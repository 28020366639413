export const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link', 'image', 'code-block'],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    [{ 'color': [] }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ["clean"],
  ],
};

export const quillFormats = [
  'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list',
  'bullet', 'indent', 'link', 'image', 'align', 'color', 'image'
];

export const displayOnOption = [
  { value: "mobile", label: "Mobile" },
  { value: "web", label: "Web" }
];

export const displayQuestionForOption = [
  { value: 'home', label: 'Home' },
  { value: 'agnihotra', label: 'Agnihotra' },
  { value: 'course', label: 'Course' },
  { value: 'article', label: 'Article' },
  // { value: 'spritual', label: 'Spritual' },
  // { value: 'family', label: 'Family' },
  // { value: 'mind', label: 'Mind' },
  // { value: 'yoga', label: 'Yoga' },
  // { value: 'health', label: 'Health' },
  // { value: 'finance', label: 'Finance' },
  { value: 'event', label: 'Event' },
  { value: 'activities', label: 'Activities' },
  { value: 'product', label: 'Product' },
]

export const moodOption = [
  { value: "angry", label: "Angry" },
  { value: "happy", label: "Happy" },
  { value: "surprised", label: "Surprised" },
  { value: "neutral", label: "Neutral" },
  { value: "confused", label: "Confused" },
]

export const categoryTypes = [
  { label: "Course", value: "course" },
  { label: "Feed", value: "feed" },
]

export const typeList = [
  {
    label: "Financial",
    value: "Financial"
  },
  {
    label: "Vastu",
    value: "Vastu"
  },
  {
    label: "Magazine",
    value: "Magazine"
  },
]

export const eventViaOption = [
  {
    label: "Online Event Registration",
    value: "Online Event Registration",
  },
  {
    label: "Offline Event Registration",
    value: "Offline Event Registration",
  },
];

export const typeOption = [
  {
    label: "INDIVIDUAL",
    value: "INDIVIDUAL",
  },
  {
    label: "GROUP",
    value: "GROUP",
  },
];

export const onOffOption = [
  {
    label: "ONLINE",
    value: "ONLINE",
  },
  {
    label: "OFFLINE",
    value: "OFFLINE",
  },
];

export const YesNoOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const typeActivityList = [
  { label: 'Spiritual', value: 'Spiritual' },
  { label: 'Tracker', value: 'Tracker' }
];

export const productTypeList = [
  { label: "Product", value: "product" },
  { label: "Spiritual", value: "spiritual" },
  { label: "Magazine", value: "magazine" }
];

export const courseTypeOption = [
  { value: "course", label: "Course" },
  { value: "agnihotra", label: "Agnihotra" }
];

// for content type
export const options = [
  { value: 'pdf', label: 'PDF' },
  { value: 'audio', label: 'Audio' }
];

// for get  type
export const typeTopicOptions = [
  { value: true, label: 'landscape' },
  { value: false, label: 'portrait' }
];

export const videoTypeOptions = [
  { value: 'video', label: 'Video' },
  { value: 'recipe_video', label: 'Recipe Video' }
];

export const typeAgnihotraOptions = [
  { value: "sunrise", label: 'Sunrise' },
  { value: "sunset", label: 'Sunset' }
];

export const genderOptions = [
  { value: "Male", label: 'Male' },
  { value: "Female", label: 'Female' },
  { value: "Other", label: 'Other' }
];

export const ageOptions = [
  { minAge: 0, maxAge: 15, label: '0-15' },
  { minAge: 16, maxAge: 18, label: '16-18' },
  { minAge: 19, maxAge: 25, label: '19-25' },
  { minAge: 26, maxAge: 40, label: '26-40' },
  { minAge: 41, maxAge: 60, label: '41-60' },
  { minAge: 61, maxAge: 100, label: '61-100' }
];



export const cityInfo = [
  {
    _id: "615c34b1d88181d6950a377c",
    countryCode: "IN",
    name: "Ahmednagar",
    stateCode: "MH",
    citycode: "ANR",
    label: "Ahmednagar",
    value: "Ahmednagar",
  },
  {
    _id: "615c34b1d88181d6950a377d",
    countryCode: "IN",
    name: "Akola",
    stateCode: "MH",
    citycode: "AKO",
    label: "Akola",
    value: "Akola",
  },
  {
    _id: "615c34b1d88181d6950a377e",
    countryCode: "IN",
    name: "Amravati",
    stateCode: "MH",
    citycode: "AMT",
    label: "Amravati",
    value: "Amravati",
  },
  {
    _id: "615c34b1d88181d6950a3780",
    countryCode: "IN",
    name: "Beed",
    stateCode: "MH",
    citycode: "BD",
    label: "Beed",
    value: "Beed",
  },
  {
    _id: "615c34b1d88181d6950a3781",
    countryCode: "IN",
    name: "Bhandara",
    stateCode: "MH",
    citycode: "BHN",
    label: "Bhandara",
    value: "Bhandara",
  },
  {
    _id: "615c34b1d88181d6950a3782",
    countryCode: "IN",
    name: "Buldhana",
    stateCode: "MH",
    citycode: "BDN",
    label: "Buldhana",
    value: "Buldhana",
  },
  {
    _id: "615c34b1d88181d6950a3783",
    countryCode: "IN",
    name: "Chandrapur",
    stateCode: "MH",
    citycode: "CDP",
    label: "Chandrapur",
    value: "Chandrapur",
  },
  {
    _id: "615c34b1d88181d6950a377f",
    countryCode: "IN",
    name: "Chhatrapati sambhajinagar (aurangabad)",
    stateCode: "MH",
    citycode: "AGB",
    label: "Chhatrapati sambhajinagar (aurangabad)",
    value: "Chhatrapati sambhajinagar (aurangabad)",
  },
  {
    _id: "615c34b1d88181d6950a3792",
    countryCode: "IN",
    name: "Dharashiv",
    stateCode: "MH",
    citycode: "OSM",
    label: "Dharashiv",
    value: "Dharashiv",
  },
  {
    _id: "615c34b1d88181d6950a3784",
    countryCode: "IN",
    name: "Dhule",
    stateCode: "MH",
    citycode: "DHL",
    label: "Dhule",
    value: "Dhule",
  },
  {
    _id: "615c34b1d88181d6950a3785",
    countryCode: "IN",
    name: "Gadchiroli",
    stateCode: "MH",
    citycode: "GAD",
    label: "Gadchiroli",
    value: "Gadchiroli",
  },
  {
    _id: "615c34b1d88181d6950a3786",
    countryCode: "IN",
    name: "Gondia",
    stateCode: "MH",
    citycode: "Gon",
    label: "Gondia",
    value: "Gondia",
  },
  {
    _id: "615c34b1d88181d6950a3787",
    countryCode: "IN",
    name: "Hingoli",
    stateCode: "MH",
    citycode: "HGL",
    label: "Hingoli",
    value: "Hingoli",
  },
  {
    _id: "615c34b1d88181d6950a3788",
    countryCode: "IN",
    name: "Jalgaon",
    stateCode: "MH",
    citycode: "JLG",
    label: "Jalgaon",
    value: "Jalgaon",
  },
  {
    _id: "615c34b1d88181d6950a3789",
    countryCode: "IN",
    name: "Jalna",
    stateCode: "MH",
    citycode: "JAL",
    label: "Jalna",
    value: "Jalna",
  },
  {
    _id: "615c34b1d88181d6950a378a",
    countryCode: "IN",
    name: "Kolhapur",
    stateCode: "MH",
    citycode: "KOP",
    label: "Kolhapur",
    value: "Kolhapur",
  },
  {
    _id: "615c34b1d88181d6950a378b",
    countryCode: "IN",
    name: "Latur",
    stateCode: "MH",
    citycode: "LAT",
    label: "Latur",
    value: "Latur",
  },
  {
    _id: "615c34b1d88181d6950a378c",
    countryCode: "IN",
    name: "Mumbai_city",
    stateCode: "MH",
    citycode: "MCT",
    label: "Mumbai_city",
    value: "Mumbai_city",
  },
  {
    _id: "615c34b1d88181d6950a378d",
    countryCode: "IN",
    name: "Mumbai_suburban",
    stateCode: "MH",
    citycode: "MSUB",
    label: "Mumbai_suburban",
    value: "Mumbai_suburban",
  },
  {
    _id: "615c34b1d88181d6950a378e",
    countryCode: "IN",
    name: "Nagpur",
    stateCode: "MH",
    citycode: "NGP",
    label: "Nagpur",
    value: "Nagpur",
  },
  {
    _id: "615c34b1d88181d6950a378f",
    countryCode: "IN",
    name: "Nanded",
    stateCode: "MH",
    citycode: "NND",
    label: "Nanded",
    value: "Nanded",
  },
  {
    _id: "615c34b1d88181d6950a3790",
    countryCode: "IN",
    name: "Nandurbar",
    stateCode: "MH",
    citycode: "NBD",
    label: "Nandurbar",
    value: "Nandurbar",
  },
  {
    _id: "615c34b1d88181d6950a3791",
    countryCode: "IN",
    name: "Nashik",
    stateCode: "MH",
    citycode: "NSK",
    label: "Nashik",
    value: "Nashik",
  },
  {
    _id: "615c34b1d88181d6950a3793",
    countryCode: "IN",
    name: "Palghar",
    stateCode: "MH",
    citycode: "PLG",
    label: "Palghar",
    value: "Palghar",
  },
  {
    _id: "615c34b1d88181d6950a3794",
    countryCode: "IN",
    name: "Parbhani",
    stateCode: "MH",
    citycode: "PBN",
    label: "Parbhani",
    value: "Parbhani",
  },
  {
    _id: "615c34b1d88181d6950a3795",
    countryCode: "IN",
    name: "Pune",
    stateCode: "MH",
    citycode: "PC",
    label: "Pune",
    value: "Pune",
  },
  {
    _id: "656033ebc94cce162e1346fa",
    countryCode: "IN",
    name: "Pune(pcmc)",
    stateCode: "MH",
    citycode: "PCMC",
    label: "Pune(pcmc)",
    value: "Pune(pcmc)",
  },
  {
    _id: "615c34b1d88181d6950a3797",
    countryCode: "IN",
    name: "Raigad",
    stateCode: "MH",
    citycode: "RGD",
    label: "Raigad",
    value: "Raigad",
  },
  {
    _id: "615c34b1d88181d6950a3798",
    countryCode: "IN",
    name: "Ratnagiri",
    stateCode: "MH",
    citycode: "RTG",
    label: "Ratnagiri",
    value: "Ratnagiri",
  },
  {
    _id: "615c34b1d88181d6950a3799",
    countryCode: "IN",
    name: "Sangli",
    stateCode: "MH",
    citycode: "SNG",
    label: "Sangli",
    value: "Sangli",
  },
  {
    _id: "615c34b1d88181d6950a379a",
    countryCode: "IN",
    name: "Satara",
    stateCode: "MH",
    citycode: "SAT",
    label: "Satara",
    value: "Satara",
  },
  {
    _id: "615c34b1d88181d6950a379b",
    countryCode: "IN",
    name: "Sindhudurg",
    stateCode: "MH",
    citycode: "SDH",
    label: "Sindhudurg",
    value: "Sindhudurg",
  },
  {
    _id: "615c34b1d88181d6950a379c",
    countryCode: "IN",
    name: "Solapur",
    stateCode: "MH",
    citycode: "SLP",
    label: "Solapur",
    value: "Solapur",
  },
  {
    _id: "615c34b1d88181d6950a379d",
    countryCode: "IN",
    name: "Thane",
    stateCode: "MH",
    citycode: "TN",
    label: "Thane",
    value: "Thane",
  },
  {
    _id: "615c34b1d88181d6950a379e",
    countryCode: "IN",
    name: "Wardha",
    stateCode: "MH",
    citycode: "WRD",
    label: "Wardha",
    value: "Wardha",
  },
  {
    _id: "615c34b1d88181d6950a379f",
    countryCode: "IN",
    name: "Washim",
    stateCode: "MH",
    citycode: "WAM",
    label: "Washim",
    value: "Washim",
  },
  {
    _id: "615c34b1d88181d6950a37a0",
    countryCode: "IN",
    name: "Yavatmal",
    stateCode: "MH",
    citycode: "YTL",
    label: "Yavatmal",
    value: "Yavatmal",
  },
];


export const oldnewOption = [
  { value: "", label: "All" },
  { value: "Old to New", label: "Old to New" },
  { value: "New to Old", label: "New to Old" },
]

export const dateOptions = [
  { value: "", label: "All" },
  { value: "today", label: "Today" },
  { value: "thisWeek", label: "This Week" },
  { value: "thisMonth", label: "This Month" },
  { value: "custom", label: "Custom Date Range" },
];

