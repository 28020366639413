import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner, Modal, InputGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '../../Common/Skeleton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';
import { setPageNo, setDocPerPage, resetData } from '../../Common/PaginationSlice';

const ManageUsers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userState = useSelector(state => state?.cmsLogin?.userData?.data);

  // for pagination
  const pageNo = useSelector((state) => state.pagination.pageNo);
  const docPerPage = useSelector((state) => state.pagination.docPerPage);

  const [data, setData] = useState('');
  const [loader, setLoader] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState(false);
  const [mobile, setMobile] = useState('');
  const [mobileErr, setMobileErr] = useState(false);
  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState(false)
  const [pass, setPass] = useState('');
  const [passErr, setPassErr] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [delId, setDelId] = useState('');
  const [showDel, setShowDel] = useState(false);
  const [searchField, setSearchField] = useState("");

  // for edit user
  const [showEdit, setShowEdit] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedMobile, setEditedMobile] = useState('');
  const [editedEmail, setEditedEmail] = useState('');

  // for validation state
  const [editedNameErr, setEditedNameErr] = useState(false);
  const [editedMobileErr, setEditedMobileErr] = useState(false);
  const [editedEmailErr, setEditedEmailErr] = useState(false);

  // for pagination
  const [noOfPages, setNoOfPages] = useState();

  // Function for handling page change
  const handlePageChange = (pageNumber) => {
    dispatch(setPageNo(pageNumber));
  };

  // Function for handling docsper page change
  const handleDocsPerPage = (docsPageProp) => {
    dispatch(setPageNo(1));
    dispatch(setDocPerPage(docsPageProp));
  };

  //----- Setting up Closing modal & Clearing Values ----//
  const closeModal = () => {
    setName("");
    setMobile("");
    setPass("");
    setEmail("");
    setNameErr(false);
    setMobileErr(false);
    setPassErr(false);
    setEmailErr(false)
    setShowAdd(false);
  }

  useEffect(() => {
    getAllUsers()
  }, [docPerPage, pageNo])


  // for get alluser
  const getAllUsers = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
      method: 'POST',
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState?.token}` },
      body: JSON.stringify({
        type: "User",
        title: searchField ? searchField : "",
        documentsPerPage: docPerPage,
        page: pageNo
      })
    }).then((res) => res.json()).then((response) => {
      if (response.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));

        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setData(response);
        setNoOfPages(response?.noOfPages);
        setLoader(false);
      }

    }).catch((err) => {
      console.log("Err while getting users", err);
      setLoader(false);
    })
  }

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllUsers, dependencies, setLoader);

  //  for add user validation
  const addValidate = () => {
    let isValid = true;
    if (!name) {
      isValid = false;
      setNameErr(true);
    }
    else if (!mobile || mobile.length < 10) {
      isValid = false;
      setMobileErr(true);
    }
    else if (!pass) {
      isValid = false;
      setPassErr(true);
    }
    return isValid;
  }

  // for add user
  const addUser = async () => {
    if (addValidate()) {
      setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/user/insertUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.token}` },
        body: JSON.stringify({
          name: name,
          mobile: mobile,
          email: email,
          password: pass,
          userType: 'admin',
        })
      }).then((res) => res.json()).then((response) => {
        if (response.message === "User registered Successfully") {
          toast.success("User Added Successfully");
          setLoader(false);
          closeModal();
          getAllUsers();
        } else if (response.message === "User is already registered.") {
          toast.error("User Already Exists");
          setLoader(false);
        }
      }).catch((err) => {
        console.log("Err while adding user", err);
        setLoader(false);
      })
    }
    else {
      setLoader(false);
    }
  }

  const openEditModal = (userData) => {
    setEditUser(userData);
    setEditedName(userData.fullName);
    setEditedMobile(userData.mobile);
    setEditedEmail(userData.email);
    setShowEdit(true);
  };

  // for close edit model
  const closeEditModal = () => {
    setShowEdit(false);
    setEditUser(null);
    setEditedName('');
    setEditedMobile('');
    setEditedEmail('');

  };

  // for edit validation model
  const validateEditedFields = () => {
    let isValid = true;

    // Validate name
    if (!editedName.trim()) {
      setEditedNameErr(true);
      isValid = false;
    } else {
      setEditedNameErr(false);
    }

    if (!editedMobile || editedMobile.length < 10) {
      setEditedMobileErr(true);
      isValid = false;
    } else {
      setEditedMobileErr(false);
    }

    if (!editedEmail.trim() || !editedEmail.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      setEditedEmailErr(true);
      isValid = false;
    } else {
      setEditedEmailErr(false);
    }

    return isValid;
  };

  // api calling for update user
  const editUserDetails = async () => {
    setLoader(true);
    try {
      if (validateEditedFields()) {
        const result = await fetch(process.env.REACT_APP_BASE_URL + '/user/updateUser', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.token}` },
          body: JSON.stringify({
            user_Id: editUser._id,
            fullName: editedName,
            mobile: editedMobile,
            email: editedEmail,
            userType: 'admin',
            updatedBy: userState?.user?.userId
          })
        });
        const response = await result.json();
        if (response.message === 'Updated successfully') {
          toast.success('User Updated Successfully');
          closeEditModal();
          getAllUsers();
        } else {
          toast.error('Failed to update user');
        }
      }
    } catch (error) {
      toast.error('Failed to update user');
    } finally {
      setLoader(false);
    }
  };

  // for delete user
  const deleteUser = async () => {
    setLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/user/deleteUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.token}` },
        body: JSON.stringify({ _id: delId })
      });
      const response = await result.json();
      if (response.message === 'User deleted successfully') {
        toast.success('User Deleted Succussfully');
        setShowDel(false);
        setDelId('');
        setLoader(false);
        getAllUsers();
      }
      else {
        toast.error('Failed to delete, try again');
        setShowDel(false);
        setDelId('');
        setLoader(false);
      }
    } catch (Err) {
      console.log("Err while deleting article", Err);
      setLoader(false);
    }
  }

  return (
    <div className='ManageUsers outletPadding'>
      <DeleteModel
        show={showDel}
        onHide={() => { setShowDel(false); setDelId(''); }}
        onDelete={deleteUser}
        loader={loader}
        label="User"
      />
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4}>
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                setSearchField(e.target.value)
                dispatch(setPageNo(1));
              }} />
          </Col>

          <Col md={2} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            <Button className='primaryBtn' onClick={() => {
              dispatch(resetData());
              setShowAdd(true)
            }}>
              <Icon.PersonAdd className='me-2' size={15} />Add User
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Email ID</th>
                <th>Role</th>
                <th>Status</th>
                <th>Registered Date</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={8} cols={8} /> :
                  data?.data !== null && data?.data !== undefined && data?.data?.length > 0 ? data?.data?.map((itm, index) => {
                    const { fullName, mobile, userType, email, userState, registrationDate, _id } = itm
                    return (
                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                        <td style={{ width: '180px' }}>{fullName}</td>
                        <td style={{ width: '150px' }}>{mobile}</td>
                        <td style={{ width: '200px' }}>{email ? email : '-'}</td>
                        <td>{userType}</td>
                        <td>{userState}</td>
                        <td style={{ width: '150px' }}>{moment(registrationDate).format("DD-MM-YYYY")}</td>
                        <td className="d-flex">
                          <img src={View} alt="View" className="icon me-3"
                            onClick={() => navigate("/dashboard/view-users", { state: { user: itm } })} />

                          <img src={Edit} alt="Edit" className="icon me-3" onClick={() => openEditModal(itm)}
                          />

                          <img src={Delete} alt="Delete" className='icon'
                            onClick={() => { setShowDel(true); setDelId(_id); }} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <PaginationSequence
          data={data?.count}
          pageNo={pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />
      </Container>

      {/* ---------------- ADD User Modal ----------------------------------------------------------- */}
      <Modal size='md' show={showAdd} backdrop='static' keyboard={false} centered onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title> <h4 className='mb-0'>
            <Icon.PersonAdd size={20} className='me-2' />Add User</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Name<span>*</span></Form.Label>
                <Form.Control placeholder="Enter name" value={name} onChange={(e) => {
                  if (!e.target.value.match(/[+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) && e.target.value.trim()) {
                    setName(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setName(e.target.value);
                  }
                  e.target.value.length > 0 ? setNameErr(false) : setNameErr(true);
                }} />
                {nameErr ? <p className='errMsg'>Please enter user name</p> : ''}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Mobile<span>*</span></Form.Label>
                <Form.Control placeholder="Enter mobile number" value={mobile} onChange={(e) => {
                  if (e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10) {
                    setMobile(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setMobile(e.target.value);
                  }
                  e.target.value.length > 0 ? setMobileErr(false) : setMobileErr(true);
                }} />
                {mobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : ''}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Email ID</Form.Label>
                <Form.Control placeholder="Enter email" value={email} onChange={(e) => {
                  setEmail(e.target.value);
                }}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Password<span>*</span></Form.Label>
                <InputGroup>
                  <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter password" value={pass}
                    onChange={(e) => {
                      setPass(e.target.value)
                      e.target.value.length > 0 ? setPassErr(false) : setPassErr(true);
                    }} />

                  <InputGroup.Text>
                    {showPassword ?
                      <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setshowPassword(!showPassword)} /> :
                      <Icon.Eye size={20} style={{ cursor: 'pointer' }} onClick={() => setshowPassword(!showPassword)} />
                    }
                  </InputGroup.Text>
                </InputGroup>
                {passErr ? <p className='errMsg'>Please enter valid password</p> : ''}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control placeholder='admin' disabled={true} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {loader ? <Spinner variant='primary' /> :
            <Button className='primaryBtn' onClick={addUser}>
              <Icon.PersonAdd className='me-1' />Add</Button>
          }
        </Modal.Footer>
      </Modal>

      {/* for edit user */}
      <Modal size='md' show={showEdit} backdrop='static' keyboard={false} centered onHide={closeEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Name<span>*</span></Form.Label>
                <Form.Control placeholder="Enter name" value={editedName} onChange={(e) => setEditedName(e.target.value)} />
                {editedNameErr && <p className='errMsg'>Please enter user name</p>}
              </Form.Group>
            </Col>


            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Mobile<span>*</span></Form.Label>
                <Form.Control placeholder="Enter mobile number" value={editedMobile} onChange={(e) => {
                  if (e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10) {
                    setEditedMobile(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setEditedMobile(e.target.value);
                  }
                }} />
                {editedMobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : ''}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Email ID<span>*</span></Form.Label>
                <Form.Control placeholder="Enter email" value={editedEmail} onChange={(e) => setEditedEmail(e.target.value)} />
                {editedEmailErr && <p className='errMsg'>Please enter valid email id</p>}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control placeholder='admin' disabled={true} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {loader ? <Spinner variant='primary' /> :
            <>
              <Button className='primaryBtn' onClick={editUserDetails}>
                <Icon.Save size={20} className='me-1' />Update
              </Button>
              <Button className='secondaryBtn' onClick={closeEditModal}>
                <Icon.X size={20} className='me-1' />Cancel
              </Button>
            </>
          }
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default ManageUsers;