import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';
import { setPageNo, setDocPerPage, resetData } from '../../Common/PaginationSlice';

const Activities = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useSelector((state) => state?.cmsLogin?.userData);

  // for pagination
  const pageNo = useSelector((state) => state.pagination.pageNo);
  const docPerPage = useSelector((state) => state.pagination.docPerPage);

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [delId, setDelId] = useState('');
  const [showDel, setShowDel] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishActivityId, setPublishActivityId] = useState(null);
  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");

  // States for Pagination ----------
  const [noOfPages, setNoOfPages] = useState();

  // Function for handling page change
  const handlePageChange = (pageNumber) => {
    dispatch(setPageNo(pageNumber));
  };

  // Function for handling docsper page change
  const handleDocsPerPage = (docsPageProp) => {
    dispatch(setPageNo(1));
    dispatch(setDocPerPage(docsPageProp));
  };

  // Function to handle opening the publish modal
  const openPublishModal = (activityId) => {
    setPublishActivityId(activityId);
    setIsPublishModalOpen(true);
  };

  // Function to handle opening the unpublish modal
  const openUnpublishModal = (activityId) => {
    setUnpublishId(activityId);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };

  useEffect(() => {
    getAllActivities()
  }, [docPerPage, pageNo])

  // ------- Get All Activities ----------------------
  const getAllActivities = async () => {
    try {
      setLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({
          title: "",
          type: "",
          startTime: "",
          endTime: "",
          tags: [],
          type: "Activity",
          title: searchField ? searchField : "",
          documentsPerPage: docPerPage,
          page: pageNo
        })
      })
      const responseData = await response.json();
      if (responseData.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));
        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setData(responseData);
        setNoOfPages(responseData?.noOfPages);
        setLoader(false);
      }

    } catch (Err) {
      console.log("Err while getting activites", Err);
      setLoader(false);
    }
  }

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllActivities, dependencies, setLoader);

  // for delete activity
  const deleteActivity = async () => {
    setLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/activity/deleteActivity', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({ activityId: delId })
      });
      const response = await result.json();
      if (response.message === 'Data deleted succussfully') {
        toast.success('Activity Deleted Succussfully');
        setShowDel(false);
        setDelId('');
        setLoader(false);
        getAllActivities();
      }
      else {
        toast.error('Failed to delete, try again');
        setShowDel(false);
        setDelId('');
        setLoader(false);
      }
    } catch (Err) {
      console.log("Err while deleting article", Err);
      setLoader(false);
    }
  }

  // for toggle
  const handlePublishAction = async (activityId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);

      const result = await fetch(process.env.REACT_APP_BASE_URL + "/activity/publishActivity", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({
          activityId: activityId,
          isPublish: isPublish
        }),
      });

      const response = await result.json();
      if (response) {
        getAllActivities();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async () => {
    await handlePublishAction(publishActivityId, true);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async () => {
    await handlePublishAction(unpublishId, false);
  };

  return (
    <div className='Activities outletPadding'>
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="Activities"
        loader={loader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="Activities"
        loader={loader}
      />

      <DeleteModel
        show={showDel}
        onHide={() => { setShowDel(false); setDelId(''); }}
        onDelete={deleteActivity}
        loader={loader}
        label="Activity"
      />
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  dispatch(setPageNo(1));
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  dispatch(setPageNo(1));
                }
              }}
            ></input>
          </Col>

          <Col md={2} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            <Button className='primaryBtn' onClick={() => {
              dispatch(resetData());
              navigate('/dashboard/add-activity')
            }}>
              <Icon.ClipboardPlus className='me-2' size={16} />Add Activity
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Image</th>
                <th>Title</th>
                <th>Tags</th>
                <th>Start Time</th>
                <th>Type</th>
                <th>Publish</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={10} cols={8} /> :
                  data?.data !== null && data?.data !== undefined && data?.data.length > 0 ? data?.data?.map((itm, index) => {
                    const { imageURL, tags, activityDayTime, type, titleLang, activityId } = itm
                    return (
                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (
                          <>
                            {' '}
                            {index + 1 + docPerPage * (pageNo - 1)}
                          </>
                        ) : (
                          <>{index + 1}</>
                        )}</td>
                        <td><img src={imageURL} alt="" width={40} /></td>
                        <td style={{ width: '200px' }}>{titleLang?.en ? titleLang?.en : ""} </td>
                        <td style={{ width: '230px' }}>{tags?.map((row) => (row)).join(', ')}</td>
                        <td>{activityDayTime ? activityDayTime : ""}</td>
                        <td>{type ? type : ""}</td>
                        <td style={{ width: '120px' }}>
                          {itm.isPublish ? (
                            <div
                              style={{
                                backgroundColor: "#8FBC8F",
                                borderRadius: "8px",
                                padding: "4px 8px",
                                color: "white",
                                textAlign: "center",
                                cursor: "pointer"
                              }}
                              onClick={() => openUnpublishModal(itm.activityId)}
                            >
                              Published
                            </div>
                          ) : (
                            <div
                              className="switch"
                              onClick={() => openPublishModal(itm.activityId)}
                            >
                              <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                              <span className="slider round" onClick={() => openPublishModal(itm.activityId)}></span>
                            </div>
                          )}

                        </td>
                        <td>
                          <img src={View} alt="View" className="icon me-3"
                            onClick={() =>
                              navigate("/dashboard/view-activities", {
                                state: {
                                  activities: itm,
                                },
                              })} />

                          <img src={Edit} alt="Edit" className="icon me-3"
                            onClick={() =>
                              navigate("/dashboard/edit-activity", {
                                state: itm

                              })}
                          />

                          <img src={Delete} alt="Delete" className='icon'
                            onClick={() => { setShowDel(true); setDelId(activityId); }} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        {/* for pagination */}
        <PaginationSequence
          data={data?.count}
          pageNo={pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />

      </Container>
    </div>
  )
}

export default Activities