import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import { Button, Col, Row } from 'react-bootstrap';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';
import { useNavigate } from 'react-router-dom';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import Skeleton from '../../Common/Skeleton';
import moment from 'moment';
import * as Icon from 'react-bootstrap-icons';
import { setPageNo, setDocPerPage } from '../../Common/PaginationSlice';


const NotificationHistory = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const userState = useSelector(state => state?.cmsLogin?.userData?.data);
    // for pagination
    const pageNo = useSelector((state) => state.pagination.pageNo);
    const docPerPage = useSelector((state) => state.pagination.docPerPage);

    // state for history data
    const [historydata, setHistoryData] = useState([])
    // Filter variables
    const [searchField, setSearchField] = useState("");
    // state for date
    const [date, setDate] = useState('')
    // state for loader
    const [loader, setLoader] = useState(false)

    // States for Pagination
    const [noOfPages, setNoOfPages] = useState(0);


    // Function for handling page change
    const handlePageChange = (pageNumber) => {
        dispatch(setPageNo(pageNumber));
    };

    // Function for handling docsper page change
    const handleDocsPerPage = (docsPageProp) => {
        dispatch(setPageNo(1));
        dispatch(setDocPerPage(docsPageProp));
    };

    useEffect(() => {
        getNotificationHistoryData()
    }, [pageNo, docPerPage])

    // for getall notification
    const getNotificationHistoryData = async (date) => {
        const payload = {
            title: searchField,
            notificationDate: date,
            documentsPerPage: docPerPage,
            page: pageNo
        }
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllNotificationHistory', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${userState?.token}`
                },
                body: JSON.stringify(payload),
            });
            const result = await response.json()
            if (result.message === "Authorization failed / Forbidden") {
                setLoader(true);
                dispatch(setLogout(null));
                dispatch(setActiveKey(null));
                localStorage.removeItem('persist:root');
                navigate('/');
            } else if (result) {
                setHistoryData(result);
                setNoOfPages(result?.noOfPages);
            } else {
                setHistoryData([]);
            }

        } catch (error) {
            console.log("Error:", error);

        }
        finally {
            setLoader(false)
        }
    }

    // for debounding
    const dependencies = [searchField ? searchField : ""];
    useDebouncedApiCall(getNotificationHistoryData, dependencies, setLoader);

    // for valid json
    const isValidJSON = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    return (
        <div className='outletPadding'>
            <Row className="justify-content-between mt-3 mt-md-0">
                <Col md={4} className="mb-2 mb-sm-0 d-flex justify-content-start align-items-center">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Search here"
                        name="search"
                        value={searchField}

                        onChange={(e) => {
                            if (e.target.value.trim()) {
                                setSearchField(e.target.value)
                                dispatch(setPageNo(1));

                            } else if (e.target.value.length === 0) {
                                setSearchField(e.target.value)
                                dispatch(setPageNo(1));

                            }
                        }}
                    />
                </Col>
                <Col md={8} className='d-flex justify-content-end align-items-center'>
                    <div className='d-flex flex-row'>
                        <input
                            type="date"
                            className="form-control me-2"
                            name="fromDate"
                            value={date ? new Date(date).toISOString().split('T')[0] : ''}
                            onChange={(e) => {
                                setDate(e.target.value)
                            }}
                        />

                        <Button className='primaryBtn me-2' onClick={() => { getNotificationHistoryData(date) }}><Icon.Search /></Button>

                        <Button className='primaryBtn' onClick={() => { getNotificationHistoryData(); setDate("") }}><Icon.ArrowClockwise size={20} /></Button>
                    </div>
                </Col>
            </Row>

            {/* Display video list */}
            <div className="outer-wrapper mx-auto mt-4">
                <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
                    <table>
                        {/* Table header */}
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Icon</th>
                                <th>User Name</th>
                                <th>Title</th>
                                <th>Screen Name</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader ? (
                                <Skeleton rows={10} cols={8} />
                            ) : historydata?.data !== null && historydata?.data !== undefined && historydata?.data?.length > 0 ? (
                                historydata?.data?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{pageNo !== 1 ? index + 1 + docPerPage * (pageNo - 1) : index + 1}</td>
                                        <td>
                                            {isValidJSON(item?.notificationData?.iconURL) ? (
                                                <a target="_blank" href={JSON.parse(item?.notificationData?.iconURL)}>
                                                    <img src={JSON.parse(item?.notificationData?.iconURL)} style={{ width: 40, height: 40 }} alt="Image" />
                                                </a>
                                            ) : (
                                                <span>Invalid URL</span>
                                            )}
                                        </td>
                                        <td style={{ width: "18vw" }}>
                                            {item?.userName ? item?.userName : '-'}
                                        </td>
                                        <td style={{ width: "18vw" }}>
                                            {item?.notificationTitle ? item?.notificationTitle : '-'}
                                        </td>
                                        <td>
                                            {isValidJSON(item?.notificationData?.screenName) ? JSON.parse(item?.notificationData?.screenName) : '-'}
                                        </td>
                                        <td>
                                            {item?.notificationDate ? moment(item?.notificationDate).format('YYYY-MM-DD') : '-'}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination */}
            <PaginationSequence
                data={historydata?.count}
                pageNo={pageNo}
                noOfPages={noOfPages}
                handlePageChange={handlePageChange}
                handleDocsPerPage={handleDocsPerPage}
                docPerPage={docPerPage}
            />
        </div>
    )
}

export default NotificationHistory