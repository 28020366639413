import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageNo: 1,
    docPerPage: 25,
    oldnew: null,
    dateFilter: null,
    startDate: null,
    endDate: null,
};

const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setPageNo(state, action) {
            state.pageNo = action.payload;
        },
        setDocPerPage(state, action) {
            state.docPerPage = action.payload;
        },
        setOldNew(state, action) {
            state.oldnew = action.payload;
        },
        setDateFilter(state, action) {
            state.dateFilter = action.payload;
        },
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
        },
        resetData(state) {
            state.pageNo = 1;
            state.docPerPage = 25;
            state.oldnew = "";
            state.dateFilter = "";
            state.startDate = "";
            state.endDate = ""
        },
        setActiveVideoPageNo: (state, action) => {
            state.activeVideoPageNo = action.payload;
        },
    },
});

export const { setPageNo, setDocPerPage, setOldNew, setDateFilter, resetData, setActiveVideoPageNo, setStartDate, setEndDate, } = paginationSlice.actions;
export default paginationSlice.reducer;
