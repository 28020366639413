import React from "react";
import { Accordion } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveKey } from "../Common/ActiveKeySlice";
import SFGFullLogo from "../../Assets/LogoFull.svg";
import { resetData } from "./PaginationSlice";

const Sidebar = () => {
  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const activeKeyRedux = useSelector((state) => state?.cmsActiveData?.activeNumberKey);

  const roles = ['admin', 'superadmin']

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(resetData());
    dispatch(
      setActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
  };

  return (
    <>
      <div className="sidenav-lg d-flex justify-content-between flex-column">
        <div>
          <div className="logoBg mt-2 mb-2">
            <img src={SFGFullLogo} alt="CMS SFG" width={80} />
            <hr />
          </div>

          <div className="menus">

            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

              {
                roles.includes(userState?.data?.user?.userType) &&
                (
                  <>
                    <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Dashboard"); navigate("/dashboard"); }}
                      className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                      <Accordion.Header> {activeKeyRedux?.activeKey === 1 ? (<Icon.HouseDoorFill size={14} className="me-2" />
                      ) : (
                        <Icon.HouseDoor size={14} className="me-2" />
                      )} Home
                      </Accordion.Header>
                    </Accordion.Item>

                    {/* <Accordion.Item eventKey={29} onClick={() => { handleAccordionChange(29, 29, "Home Banner"); navigate("/dashboard/homebanner"); }}
                      className={activeKeyRedux?.activeKey === 29 ? " activeMenu" : ""}>
                      <Accordion.Header> {activeKeyRedux?.activeKey === 29 ? (<Icon.FileImageFill size={14} className="me-2" />
                      ) : (
                        <Icon.FileImage size={14} className="me-2" />
                      )} Home Banner
                      </Accordion.Header>
                    </Accordion.Item> */}
                  </>
                )



              }
              {
                userState?.data?.user?.userType === "superadmin" &&
                (
                  <>
                    {/* <Accordion.Item
                      eventKey={2}
                      onClick={() => {
                        handleAccordionChange(2, 2, "Audit Log");
                        navigate("/dashboard/auditlog");
                      }}
                      className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                      <Accordion.Header>
                        {activeKeyRedux?.activeKey === 2 ? (
                          <Icon.BarChartFill className="me-2" />
                        ) : (
                          <Icon.BarChart className="me-2" />
                        )} Audit Log
                      </Accordion.Header>
                    </Accordion.Item> */}

                    <Accordion.Item
                      eventKey={3}
                      onClick={() => {
                        handleAccordionChange(3, 3, "Manage Users");
                        navigate("/dashboard/manage-users");
                      }}
                      className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                      <Accordion.Header>
                        {activeKeyRedux?.activeKey === 3 ? (
                          <Icon.PersonFill className="me-2" />
                        ) : (
                          <Icon.Person className="me-2" />
                        )} Users
                      </Accordion.Header>
                    </Accordion.Item>
                  </>

                )
              }

              {
                roles.includes(userState?.data?.user?.userType) &&
                <>
                  <Accordion.Item
                    eventKey={4}
                    onClick={() => {
                      handleAccordionChange(4, 4, "Category");
                      navigate("/dashboard/category");
                    }}
                    className={activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""}
                  >
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 4 ? (
                        <Icon.GridFill className="me-2" />
                      ) : (
                        <Icon.Grid className="me-2" />
                      )}
                      Category
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={30}
                    onClick={() => {
                      handleAccordionChange(30, 30, "Mood");
                      navigate("/dashboard/mood");
                    }}
                    className={activeKeyRedux?.activeKey === 30 ? "activeMenu" : ""}
                  >
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 30 ? (
                        <Icon.EmojiLaughingFill className="me-2" />
                      ) : (
                        <Icon.EmojiLaughing className="me-2" />
                      )}
                      Mood
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={22}
                    onClick={() => {
                      handleAccordionChange(22, 22, "Page");
                      navigate("/dashboard/page");
                    }}
                    className={activeKeyRedux?.activeKey === 22 ? "activeMenu" : ""}
                  >
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 22 ? (
                        <Icon.FileEarmarkFill className="me-2" />
                      ) : (
                        <Icon.FileEarmark className="me-2" />
                      )}
                      Page
                    </Accordion.Header>
                  </Accordion.Item>

                  {/* <Accordion.Item
                    eventKey={5}
                    onClick={() => {
                      handleAccordionChange(5, 5, "Manage Priority");
                      navigate("/dashboard/priority");
                    }}
                    className={activeKeyRedux?.activeKey === 5 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 5 ? (
                        <Icon.BorderStyle className="me-2" />
                      ) : (
                        <Icon.BorderWidth className="me-2" />
                      )}
                      Priority
                    </Accordion.Header>
                  </Accordion.Item> */}

                  <Accordion.Item
                    eventKey={20}
                    onClick={() => {
                      handleAccordionChange(20, 20, "Panchang List");
                      navigate("/dashboard/panchang");
                    }}
                    className={activeKeyRedux?.activeKey === 20 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 20 ? (
                        <Icon.CalendarEventFill className="me-2" />
                      ) : (
                        <Icon.CalendarEvent className="me-2" />
                      )}
                      Panchang
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={25}
                    onClick={() => {
                      handleAccordionChange(25, 25, "Agnihotra List");
                      navigate("/dashboard/agnihotra");
                    }}
                    className={activeKeyRedux?.activeKey === 25 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 25 ? (
                        <Icon.Fire className="me-2" />
                      ) : (
                        <Icon.Fire className="me-2" />
                      )}
                      Agnihotra
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={6}
                    onClick={() => {
                      handleAccordionChange(6, 6, "Article List");
                      navigate("/dashboard/articleList");
                    }}
                    className={activeKeyRedux?.activeKey === 6 ? " activeMenu" : ""}
                  >
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 6 ? (
                        <Icon.PostcardHeartFill className="me-2" />
                      ) : (
                        <Icon.PostcardHeart className="me-2" />
                      )}
                      Article
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={7}
                    onClick={() => {
                      handleAccordionChange(7, 7, "Event List");
                      navigate("/dashboard/eventList");
                    }}
                    className={activeKeyRedux?.activeKey === 7 ? " activeMenu" : ""}
                  >
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 7 ? (
                        <Icon.Calendar2WeekFill className="me-2" />
                      ) : (
                        <Icon.Calendar2Week className="me-2" />
                      )}
                      Event
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={8}
                    onClick={() => {
                      handleAccordionChange(8, 8, "Activities");
                      navigate("/dashboard/activities");
                    }}
                    className={activeKeyRedux?.activeKey === 8 ? " activeMenu" : ""}
                  >
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 8 ? (
                        <Icon.Clipboard2PulseFill className="me-2" />
                      ) : (
                        <Icon.Clipboard2Pulse className="me-2" />)}
                      Activities
                    </Accordion.Header>
                  </Accordion.Item>

                  {/* <Accordion.Item
                    eventKey={9}
                    onClick={() => {
                      handleAccordionChange(9, 9, "Product");
                      navigate("/dashboard/shoppee");
                    }}
                    className={activeKeyRedux?.activeKey === 9 ? " activeMenu" : ""}
                  >
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 9 ? (
                        <Icon.BagHeartFill className="me-2" />
                      ) : (
                        <Icon.BagHeart className="me-2" />)}
                      Product
                    </Accordion.Header>
                  </Accordion.Item> */}



                  <Accordion.Item
                    eventKey={10}
                    onClick={() => {
                      handleAccordionChange(10, 10, "Course");
                      navigate("/dashboard/course");
                    }}
                    className={activeKeyRedux?.activeKey === 10 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 10 ? (
                        <Icon.JournalBookmarkFill className="me-2" />
                      ) : (
                        <Icon.JournalBookmark className="me-2" />)}
                      Course
                    </Accordion.Header>
                  </Accordion.Item>

                  {/* <Accordion.Item
                    eventKey={11}
                    onClick={() => {
                      handleAccordionChange(11, 11, "Teacher");
                      navigate("/dashboard/teacher");
                    }}
                    className={activeKeyRedux?.activeKey === 11 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 11 ? (
                        <Icon.PersonWorkspace className="me-2" />
                      ) : (
                        <Icon.PersonSquare className="me-2" />)}
                      Teacher
                    </Accordion.Header>
                  </Accordion.Item> */}

                  <Accordion.Item
                    eventKey={12}
                    onClick={() => {
                      handleAccordionChange(12, 12, "Guru And Quotes");
                      navigate("/dashboard/guru-and-quote");
                    }}
                    className={activeKeyRedux?.activeKey === 12 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 12 ? (
                        <Icon.ChatQuoteFill className="me-2" />
                      ) : (
                        <Icon.ChatQuote className="me-2" />)}Guru & Quotes
                    </Accordion.Header>
                  </Accordion.Item>


                  <Accordion.Item eventKey={13}
                    onClick={() => {
                      handleAccordionChange(13, 13, "Tags");
                      navigate("/dashboard/tags");
                    }}
                    className={activeKeyRedux?.activeKey === 13 ? " activeMenu" : ""}>
                    <Accordion.Header>

                      {activeKeyRedux?.activeKey === 13 ? (
                        <Icon.TagsFill className="me-2" />
                      ) : (
                        <Icon.Tags className="me-2" />
                      )}
                      Tags
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={14}
                    onClick={() => {
                      handleAccordionChange(14, 14, "Video List");
                      navigate("/dashboard/content-list");
                    }}
                    className={activeKeyRedux?.activeKey === 14 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 14 ? (
                        <Icon.CameraVideoFill className="me-2" />
                      ) : (
                        <Icon.CameraVideo className="me-2" />
                      )}
                      Video List
                    </Accordion.Header>
                  </Accordion.Item>
                </>
              }
              {userState?.data?.user?.userType === "superadmin" &&

                <>

                  <Accordion.Item
                    eventKey={15}
                    onClick={() => {
                      handleAccordionChange(15, 15, "Transaction");
                      navigate("/dashboard/transaction");
                    }}
                    className={activeKeyRedux?.activeKey === 15 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 15 ? (
                        <Icon.WalletFill className="me-2" />
                      ) : (
                        <Icon.Wallet2 className="me-2" />
                      )}Transactions
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={16}
                    onClick={() => {
                      handleAccordionChange(16, 16, "Subscriptions");
                      navigate("/dashboard/subscription-user");
                    }}
                    className={activeKeyRedux?.activeKey === 16 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 16 ? (
                        <Icon.CurrencyRupee className="me-2" />
                      ) : (
                        <Icon.CurrencyRupee className="me-2" />
                      )}
                      Subscriptions
                    </Accordion.Header>
                  </Accordion.Item>
                </>
              }
              {roles.includes(userState?.data?.user?.userType) &&

                <>

                  <Accordion.Item
                    eventKey={18}
                    onClick={() => {
                      handleAccordionChange(18, 18, "Notification");
                      navigate("/dashboard/notification");
                    }}
                    className={activeKeyRedux?.activeKey === 18 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 18 ? (
                        <Icon.BellFill className="me-2" />
                      ) : (
                        <Icon.Bell className="me-2" />
                      )}Notification
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={28}
                    onClick={() => {
                      handleAccordionChange(28, 28, "Notification History");
                      navigate("/dashboard/notificationhistory");
                    }}
                    className={activeKeyRedux?.activeKey === 28 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 28 ? (
                        <Icon.ClockFill className="me-2" />
                      ) : (
                        <Icon.Clock className="me-2" />
                      )}Notification History
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={19}
                    onClick={() => {
                      handleAccordionChange(19, 19, "FAQ");
                      navigate("/dashboard/faq");
                    }}
                    className={activeKeyRedux?.activeKey === 19 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 19 ? (
                        <Icon.QuestionCircleFill className="me-2" />
                      ) : (
                        <Icon.QuestionCircle className="me-2" />
                      )}FAQ
                    </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey={17}
                    onClick={() => {
                      handleAccordionChange(17, 17, "Help & Support");
                      navigate("/dashboard/help-and-support");
                    }}
                    className={activeKeyRedux?.activeKey === 17 ? " activeMenu" : ""}>
                    <Accordion.Header>
                      {activeKeyRedux?.activeKey === 17 ? (
                        <Icon.InfoCircleFill className="me-2" />
                      ) : (
                        <Icon.InfoCircle className="me-2" />
                      )}
                      Help & Support
                    </Accordion.Header>
                  </Accordion.Item>

                </>
              }

            </Accordion>

          </div>
        </div>
      </div >
    </>
  );
};

export default Sidebar;
