import { React, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Skeleton from '../../Common/Skeleton';
import moment from 'moment';
import Pagination from '../../Common/Pagination';
import View from '../../../Assets/View.svg';
import { useNavigate } from 'react-router-dom';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import { setPageNo, setDocPerPage } from '../../Common/PaginationSlice';


const NotSubscriptionUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useSelector((state) => state?.cmsLogin?.userData);
  // for pagination
  const pageNo = useSelector((state) => state.pagination.pageNo);
  const docPerPage = useSelector((state) => state.pagination.docPerPage);

  // States for Pagination ----------
  const [noOfPages, setNoOfPages] = useState();

  const [loader, setLoader] = useState(false)
  const [unSubscriptionUsers, setUnSubscriptionUsers] = useState([])
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    getSubscriptionUsers()
  }, [docPerPage, pageNo])

  const getSubscriptionUsers = async () => {
    setLoader(true)
    await fetch(process.env.REACT_APP_BASE_URL + '/cms/getUnsubscribedUsers', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
      body: JSON.stringify({
        name: searchField ? searchField : "",
        documentsPerPage: docPerPage,
        page: pageNo
      })
    })
      .then((response) => response.json())
      .then((res) => {
        setUnSubscriptionUsers(res?.data)
        setNoOfPages(res?.data?.noOfPages);
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        console.log("error", error);
      })
  }


  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getSubscriptionUsers, dependencies, setLoader);


  // Function for handling page change
  const handlePageChange = (pageNumber) => {
    dispatch(setPageNo(pageNumber));
  };

  // Function for handling docsper page change
  const handleDocsPerPage = (docsPageProp) => {
    dispatch(setPageNo(1));
    dispatch(setDocPerPage(docsPageProp));
  };

  return (
    <div className='mt-5'>
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4}>
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  dispatch(setPageNo(1));
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  dispatch(setPageNo(1));
                }
              }}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <h5>Total Count : <span>{unSubscriptionUsers?.count}</span></h5>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Date of Birth</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={8} cols={5} /> :
                  unSubscriptionUsers?.data !== null && unSubscriptionUsers?.data !== undefined && unSubscriptionUsers?.data?.length > 0 ? unSubscriptionUsers?.data?.map((itm, index) => {
                    const { fullName, mobile, dob } = itm
                    return (
                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                        <td>{fullName}</td>
                        <td>{mobile}</td>
                        <td>{moment(dob).format("DD-MM-YYYY")}</td>
                        <td className="d-flex">
                          <img src={View} alt="View" className="icon me-3"
                            onClick={() =>
                              navigate("/dashboard/view-notsubscription-user", {
                                state: {
                                  user: itm,
                                },
                              })} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <Row>
          <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
              {/* <Icon.People className='me-2' />Total - <strong>{data?.count}</strong> */}
            </h6>
          </Col>
          <Col md={8} className='d-flex justify-content-end'>
            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NotSubscriptionUser
